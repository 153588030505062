/// <reference path="../../scripts/typings/wow/index.d.ts" />
/// <reference path="../../node_modules/@types/dw-bxslider-4/index.d.ts" />

"use strict";

module DBS.Startup
{
    const targetWidth = 220;
    const slideMargin = 30;

    let slider: bxSlider;

    function determineSlideWidth(): number
    {
        const wrap = $(".slider4")[0] as HTMLElement;
        if (wrap == null)
        {
            return 0;
        }

        const maxWidth = wrap.clientWidth;

        const count = Math.round(maxWidth / targetWidth);
        const width = Math.floor((maxWidth - (slideMargin * (count - 0.5))) / count);
        return width;
    }

    function generateOptions(currentSlide = 0): bxSliderOptions
    {
        return {
            slideMargin: slideMargin,
            minSlides: 1,
            maxSlides: 5,
            moveSlides: 1,
            slideWidth: determineSlideWidth(),
            auto: true,
            autoStart: true,
            pause: 4000,
            pager: false,
            startSlide: currentSlide,
            prevText: "<i class=\"fa fa-chevron-left\"></i>",
            nextText: "<i class=\"fa fa-chevron-right\"></i>"
        };
    }

    function resizeSlider()
    {
        if (slider == null)
        {
            return;
        }

        const currentSlide = slider.getCurrentSlide();
        slider.destroySlider();

        slider = $(".slider4").bxSlider(generateOptions(currentSlide));
    }

    export function Initialize()
    {
        const opt = generateOptions();
        slider = $(".slider4").bxSlider(opt);

        const wow = new WOW(
            {
                boxClass: "wow", // default
                animateClass: "animated", // default
                offset: 0, // default
                mobile: true, // default
                live: true // default
            }
        );
        wow.init();

        window.addEventListener("resize", resizeSlider);
    }
}

window.addEventListener("load", DBS.Startup.Initialize);